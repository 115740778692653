const environment = {
  // SECRETS
  MASTER_KEY: process.env.MASTER_KEY,
  DATABASE_HOST: process.env.DATABASE_HOST,
  DATABASE_USERNAME: process.env.DATABASE_USERNAME,
  DATABASE_PASSWORD: process.env.DATABASE_PASSWORD,
  UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
  SLACK_HOOK_ONBOARDINGS_URL: process.env.SLACK_HOOK_ONBOARDINGS_URL,
  PINATA_API_KEY: process.env.PINATA_API_KEY,
  DEXGURU_API_KEY: process.env.DEXGURU_API_KEY,
  COVALENT_API_KEY: process.env.COVALENT_API_KEY,
  ANKR_API_URL: process.env.ANKR_API_URL,
  RESEND_API_KEY: process.env.RESEND_API_KEY,
  POSTHOG_API_KEY: process.env.POSTHOG_API_KEY ?? process.env.NEXT_PUBLIC_POSTHOG_API_KEY,
  PRIVY_API_KEY: process.env.PRIVY_API_KEY,
  PRIVY_VERIFICATION_KEY: process.env.PRIVY_VERIFICATION_KEY,

  // PLAIN
  UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
  UPLOADS_BUCKET_URL: process.env.UPLOADS_BUCKET_URL,
  POSTHOG_HOST: process.env.POSTHOG_HOST ?? process.env.NEXT_PUBLIC_POSTHOG_HOST,
  PRIVY_APP_ID: process.env.PRIVY_APP_ID ?? process.env.NEXT_PUBLIC_PRIVY_APP_ID,

  // PIPELINE
  // FROM GITHUB ACTIONS
  API_URL: process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL,
  WEB_URL: process.env.WEB_URL ?? process.env.NEXT_PUBLIC_WEB_URL,
  WIDGET_URL: process.env.WIDGET_URL ?? process.env.NEXT_PUBLIC_WIDGET_URL,
  // FROM SERVERLESS
  PINATA_BASE_FOLDER: process.env.PINATA_BASE_FOLDER,
  REGION: process.env.REGION,
  UPLOAD_BUCKET_NAME: process.env.UPLOAD_BUCKET_NAME,
  SLACK_QUEUE_URL: process.env.SLACK_QUEUE_URL,
  UPDATE_METRICS_STATE_MACHINE_ARN: process.env.UPDATE_METRICS_STATE_MACHINE_ARN
} as const;

type EnvironmentKeys = keyof typeof environment;

export default environment as Record<EnvironmentKeys, string>;
