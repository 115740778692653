import type { AxiosResponse, RawAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

import { useCallback } from 'react';

import { type ApiRoutesData, apiRoutes, type ApiRoutesReturnTypes, requestApi } from '@trustblock/web/helpers/api';
import { usePrivy } from '@privy-io/react-auth';

function useApi() {
  const { getAccessToken } = usePrivy();

  const requestFunction = useCallback(
    async <K extends keyof typeof apiRoutes>({
      route,
      data,
      withAuthentication = false,
      extraHeaders,
      validateStatus
    }: {
      route: ReturnType<(typeof apiRoutes)[K]>;
      data?: ApiRoutesData[K];
      withAuthentication?: boolean;
      extraHeaders?: RawAxiosRequestHeaders;
      validateStatus?: RawAxiosRequestConfig['validateStatus'];
    }): Promise<AxiosResponse<ApiRoutesReturnTypes[K]>> => {
      const finalAccessToken = await getAccessToken();
      const headers = {
        Authorization: withAuthentication ? `Bearer ${finalAccessToken}` : undefined,
        ...extraHeaders
      };

      return requestApi<K>({
        route,
        data,
        headers,
        validateStatus
      });
    },
    [getAccessToken]
  );

  return { request: requestFunction };
}

export default useApi;
