import environment from '@trustblock/constants/environment.constants';
import posthog from 'posthog-js';

export const events = {
  auditRequest: 'Audit Request'
} as const;

type Event = (typeof events)[keyof typeof events];
type EventsKeys = keyof typeof events;

interface EventParams {
  auditRequest: { auditorSlug: string };
}

const isProduction = environment.WEB_URL === 'https://beta.trustblock.run';

export function initPosthog() {
  if (!isProduction) return;
  posthog.init(environment.POSTHOG_API_KEY, {
    api_host: environment.POSTHOG_HOST
  });
}

export default function sendEvent<T extends EventsKeys>(event: Event, params: EventParams[T]) {
  if (!isProduction) return;
  posthog.capture(`[WEB] ${event}`, { ...params, env: 'WEB' });
}
