// To add a new icon
// 1. Download it on lucide.dev or export from figma if homemade
// 2. Remove width and height attributes on svg tag in the file
// 3. Add here and order alphabetically please
// 4. Add the name in options.json
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

const Address = dynamic(() => import('./address.svg'));
const Award = dynamic(() => import('./award.svg'));
const AlertCircle = dynamic(() => import('./alert-circle.svg'));
const AlertTriangle = dynamic(() => import('./alert-triangle.svg'));
const Arbitrum = dynamic(() => import('./arbitrum.svg'));
const ArrowDown = dynamic(() => import('./arrow-down.svg'));
const ArrowLeft = dynamic(() => import('./arrow-left.svg'));
const ArrowLeftRight = dynamic(() => import('./arrow-left-right.svg'));
const ArrowRight = dynamic(() => import('./arrow-right.svg'));
const ArrowUp = dynamic(() => import('./arrow-up.svg'));
const ArrowUpRight = dynamic(() => import('./arrow-up-right.svg'));
const Avalanche = dynamic(() => import('./avalanche.svg'));
const Bnb = dynamic(() => import('./bnb.svg'));
const Bold = dynamic(() => import('./bold.svg'));
const Braces = dynamic(() => import('./braces.svg'));
const BookOpen = dynamic(() => import('./book-open.svg'));
const Box = dynamic(() => import('./box.svg'));
const Briefcase = dynamic(() => import('./briefcase.svg'));
const Cable = dynamic(() => import('./cable.svg'));
const CalendarCheck = dynamic(() => import('./calendar-check.svg'));
const Cake = dynamic(() => import('./cake.svg'));
const CandlestickChart = dynamic(() => import('./candlestick-chart.svg'));
const Check = dynamic(() => import('./check.svg'));
const CheckCheck = dynamic(() => import('./check-check.svg'));
const CheckCircle = dynamic(() => import('./check-circle.svg'));
const CheckCircle2 = dynamic(() => import('./check-circle-2.svg'));
const ChevronDown = dynamic(() => import('./chevron-down.svg'));
const ChevronUp = dynamic(() => import('./chevron-up.svg'));
const ChevronsRight = dynamic(() => import('./chevrons-right.svg'));
const Code = dynamic(() => import('./code.svg'));
const Code2 = dynamic(() => import('./code-2.svg'));
const Coinbase = dynamic(() => import('./coinbase.svg'));
const Coins = dynamic(() => import('./coins.svg'));
const Collectibles = dynamic(() => import('./collectibles.svg'));
const Copy = dynamic(() => import('./copy.svg'));
const CornerUpLeft = dynamic(() => import('./corner-up-left.svg'));
const CornerUpRight = dynamic(() => import('./corner-up-right.svg'));
const Disconnect = dynamic(() => import('./disconnect.svg'));
const Discord = dynamic(() => import('./discord.svg'));
const DollarSign = dynamic(() => import('./dollar-sign.svg'));
const Ethereum = dynamic(() => import('./ethereum.svg'));
const EtherscanLogoCircle = dynamic(() => import('./etherscan-logo-circle.svg'));
const Eye = dynamic(() => import('./eye.svg'));
const EyeOff = dynamic(() => import('./eye-off.svg'));
const Fantom = dynamic(() => import('./fantom.svg'));
const Feather = dynamic(() => import('./feather.svg'));
const File = dynamic(() => import('./file.svg'));
const FileBadge = dynamic(() => import('./file-badge.svg'));
const FileCode = dynamic(() => import('./file-code.svg'));
const FileQuestion = dynamic(() => import('./file-question.svg'));
const Forward = dynamic(() => import('./forward.svg'));
const Gamepad = dynamic(() => import('./gamepad.svg'));
const Github = dynamic(() => import('./github.svg'));
const Globe = dynamic(() => import('./globe.svg'));
const Globe2 = dynamic(() => import('./globe-2.svg'));
const ImagePlus = dynamic(() => import('./image-plus.svg'));
const Hash = dynamic(() => import('./hash.svg'));
const HeartHandshake = dynamic(() => import('./heart-handshake.svg'));
const HelpCircle = dynamic(() => import('./help-circle.svg'));
const Hexagon = dynamic(() => import('./hexagon.svg'));
const Info = dynamic(() => import('./info.svg'));
const Italic = dynamic(() => import('./italic.svg'));
const Landmark = dynamic(() => import('./landmark.svg'));
const Ledger = dynamic(() => import('./ledger.svg'));
const Layers = dynamic(() => import('./layers.svg'));
const Link = dynamic(() => import('./link.svg'));
const Link2 = dynamic(() => import('./link-2.svg'));
const List = dynamic(() => import('./list.svg'));
const Lock = dynamic(() => import('./lock.svg'));
const Logo = dynamic(() => import('./logo.svg'));
const LogoFullBlack = dynamic(() => import('./logo-full-black.svg'));
const LogoMarkBlack = dynamic(() => import('./logo-mark-black.svg'));
const Login = dynamic(() => import('./log-in.svg'));
const Logout = dynamic(() => import('./log-out.svg'));
const Mail = dynamic(() => import('./mail.svg'));
const Menu = dynamic(() => import('./menu.svg'));
const MinusCircle = dynamic(() => import('./minus-circle.svg'));
const ListOrdered = dynamic(() => import('./list-ordered.svg'));
const MetaMask = dynamic(() => import('./metamask.svg'));
const Optimism = dynamic(() => import('./optimism.svg'));
const PiggyBank = dynamic(() => import('./piggy-bank.svg'));
const Plug = dynamic(() => import('./plug.svg'));
const Plus = dynamic(() => import('./plus.svg'));
const Polygon = dynamic(() => import('./polygon.svg'));
const PolygonPurple = dynamic(() => import('./polygon-purple.svg'));
const Quote = dynamic(() => import('./quote.svg'));
const Radar = dynamic(() => import('./radar.svg'));
const Repeat = dynamic(() => import('./repeat.svg'));
const Search = dynamic(() => import('./search.svg'));
const Server = dynamic(() => import('./server.svg'));
const ServerOff = dynamic(() => import('./server-off.svg'));
const Settings = dynamic(() => import('./settings.svg'));
const Shield = dynamic(() => import('./shield.svg'));
const ShieldCheck = dynamic(() => import('./shield-check.svg'));
const ShieldX = dynamic(() => import('./shield-x.svg'));
const Slash = dynamic(() => import('./slash.svg'));
const Star = dynamic(() => import('./star.svg'));
const Strikethrough = dynamic(() => import('./strikethrough.svg'));
const Sparkles = dynamic(() => import('./sparkles.svg'));
const Telegram = dynamic(() => import('./telegram.svg'));
const Tags = dynamic(() => import('./tags.svg'));
const ThumbsUp = dynamic(() => import('./thumbs-up.svg'));
const Token = dynamic(() => import('./token.svg'));
const ToyBrick = dynamic(() => import('./toy-brick.svg'));
const Twitter = dynamic(() => import('./twitter.svg'));
const User = dynamic(() => import('./user.svg'));
const Users = dynamic(() => import('./users.svg'));
const Usb = dynamic(() => import('./usb.svg'));
const Wallet = dynamic(() => import('./wallet.svg'));
const WalletConnect = dynamic(() => import('./walletconnect.svg'));
const Wrench = dynamic(() => import('./wrench.svg'));
const X = dynamic(() => import('./x.svg'));
const XCircle = dynamic(() => import('./x-circle.svg'));
const Youtube = dynamic(() => import('./youtube.svg'));
const Zksync = dynamic(() => import('./zksync.svg'));

export const ICONS = {
  Address,
  Award,
  AlertCircle,
  AlertTriangle,
  Arbitrum,
  ArrowDown,
  ArrowLeft,
  ArrowLeftRight,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
  Avalanche,
  Bnb,
  Bold,
  Braces,
  BookOpen,
  Box,
  Briefcase,
  Cable,
  CalendarCheck,
  Cake,
  CandlestickChart,
  Check,
  CheckCircle,
  CheckCircle2,
  CheckCheck,
  ChevronDown,
  ChevronUp,
  ChevronsRight,
  Code,
  Code2,
  Coinbase,
  Coins,
  Collectibles,
  Copy,
  CornerUpLeft,
  CornerUpRight,
  Disconnect,
  Discord,
  DollarSign,
  Ethereum,
  EtherscanLogoCircle,
  Eye,
  EyeOff,
  Fantom,
  Feather,
  File,
  FileBadge,
  FileCode,
  FileQuestion,
  Forward,
  Gamepad,
  Github,
  Globe,
  Globe2,
  ImagePlus,
  Hash,
  HeartHandshake,
  HelpCircle,
  Hexagon,
  Info,
  Italic,
  Landmark,
  Ledger,
  Layers,
  Link,
  Link2,
  List,
  ListOrdered,
  Lock,
  Logo,
  LogoFullBlack,
  LogoMarkBlack,
  Login,
  Logout,
  Mail,
  Menu,
  MinusCircle,
  MetaMask,
  Optimism,
  PiggyBank,
  Plug,
  Plus,
  Polygon,
  PolygonPurple,
  Quote,
  Radar,
  Repeat,
  Search,
  Server,
  ServerOff,
  Settings,
  Shield,
  ShieldCheck,
  ShieldX,
  Slash,
  Star,
  Strikethrough,
  Sparkles,
  Telegram,
  Tags,
  ThumbsUp,
  Token,
  ToyBrick,
  Twitter,
  Usb,
  User,
  Users,
  Wallet,
  WalletConnect,
  Wrench,
  X,
  XCircle,
  Youtube,
  Zksync
} as const;

export const getIconComponent = (name: keyof typeof ICONS) =>
  ICONS[name as keyof typeof ICONS] as ComponentType<{ className: string }>;
