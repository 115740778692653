import axios, { RawAxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import {
  CreateAuditorResponse,
  GetAuditorPageDataResponse,
  GetUploadReportAuthorizationResponse,
  SearchAuditorsResponse,
  UpdateAuditorResponse
} from '@trustblock/types/auditor.types';
import { GetProjectPageDataResponse, SearchProjectsResponse } from '@trustblock/types/project.types';
import { HttpMethod } from '@trustblock/types/http.types';
import environment from '@trustblock/constants/environment.constants';

import {
  CreateIntegratorResponse,
  GetIntegratorPageDataResponse,
  UpdateIntegratorResponse
} from '@trustblock/types/integrator.types';
import { CreateAuditResponse, GetAuditPageDataResponse } from '@trustblock/types/audit.types';
import { GetUserResponse, UpdateUserProfilePictureResponse } from '@trustblock/types/user.types';
import {
  CreateAuditorParams,
  SearchAuditorsParams,
  UpdateAuditorParams
} from '@trustblock/validators/auditor.validators';
import { SearchProjectsParams } from '@trustblock/validators/project.validators';
import { CreateIntegratorParams, UpdateIntegratorParams } from '@trustblock/validators/integrator.validators';
import { CreateAuditParams } from '@trustblock/validators/audit.validators';

export const apiRoutes = {
  searchAuditors: () => [HttpMethod.Get, 'ui/auditor/search'] as const,
  searchProjects: () => [HttpMethod.Get, 'ui/project/search'] as const,
  createAuditor: () => [HttpMethod.Post, 'auditor'] as const,
  createIntegrator: () => [HttpMethod.Post, 'integrator'] as const,
  publishAudit: () => [HttpMethod.Post, 'v1/audit'] as const,
  getUser: () => [HttpMethod.Get, 'user'] as const,
  getUploadReportAuthorization: () => [HttpMethod.Get, 'v1/auditor/upload-report-authorization'] as const,
  updateUserProfilePicture: () => [HttpMethod.Post, 'user/upload/profile-picture'] as const,
  updateIntegrator: () => [HttpMethod.Patch, 'integrator'] as const,
  updateAuditor: () => [HttpMethod.Patch, 'auditor'] as const,
  getIntegratorPageData: (slug: string) => [HttpMethod.Get, `ui/integrator/${slug}`, true] as const,
  getAuditorPageData: (slug: string) => [HttpMethod.Get, `ui/auditor/${slug}`, true] as const,
  getAuditPageData: (id: string) => [HttpMethod.Get, `ui/audit/${id}`, true] as const,
  getProjectPageData: (slug: string) => [HttpMethod.Get, `ui/project/${slug}`, true] as const
} as const;

export interface ApiRoutesResponses {
  searchAuditors: SearchAuditorsResponse;
  searchProjects: SearchProjectsResponse;
  createAuditor: CreateAuditorResponse;
  createIntegrator: CreateIntegratorResponse;
  publishAudit: CreateAuditResponse;
  getUser: GetUserResponse;
  getUploadReportAuthorization: GetUploadReportAuthorizationResponse;
  updateUserProfilePicture: UpdateUserProfilePictureResponse;
  updateIntegrator: UpdateIntegratorResponse;
  updateAuditor: UpdateAuditorResponse;
  getIntegratorPageData: GetIntegratorPageDataResponse;
  getAuditorPageData: GetAuditorPageDataResponse;
  getAuditPageData: GetAuditPageDataResponse;
  getProjectPageData: GetProjectPageDataResponse;
}

export interface ApiRoutesParams {
  searchAuditors: SearchAuditorsParams;
  searchProjects: SearchProjectsParams;
  createAuditor: CreateAuditorParams;
  createIntegrator: CreateIntegratorParams;
  publishAudit: CreateAuditParams;
  getUser: undefined;
  getUploadReportAuthorization: undefined;
  updateUserProfilePicture: FormData;
  updateIntegrator: UpdateIntegratorParams;
  updateAuditor: UpdateAuditorParams;
  getIntegratorPageData: undefined;
  getAuditorPageData: undefined;
  getAuditPageData: undefined;
  getProjectPageData: undefined;
}

export type ApiRoutesReturnTypes = {
  [K in keyof typeof apiRoutes]: ApiRoutesResponses[K];
};

export type ApiRoutesData = {
  [K in keyof typeof apiRoutes]: ApiRoutesParams[K];
};

export async function requestApi<K extends keyof typeof apiRoutes>({
  route,
  data,
  headers,
  validateStatus
}: {
  route: ReturnType<(typeof apiRoutes)[K]>;
  data?: ApiRoutesData[K];
  headers?: RawAxiosRequestHeaders;
  validateStatus?: RawAxiosRequestConfig['validateStatus'];
}) {
  const [method, url, isMaster] = route;

  return axios<ApiRoutesReturnTypes[K]>(`${environment.API_URL}/${url}`, {
    method,
    data,
    params: method === HttpMethod.Get ? data : undefined,
    paramsSerializer: (params) =>
      Object.entries(params)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}=${value.join(',')}`;
          }
          if (value) return `${key}=${value}`;
          return null;
        })
        .filter(Boolean)
        .join('&'),
    headers: {
      ...headers,
      'x-trustblock-master-key': isMaster ? environment.MASTER_KEY : undefined
    },
    validateStatus: validateStatus ?? ((status) => status < 400)
  });
}
