import Icon from '@trustblock/components/standalone/Icon/Icon';
import Link from 'next/link';
import React from 'react';
import styles from './Navbar.module.scss';

interface NavbarProps {
  children?: React.ReactNode;
}

function Navbar({ children }: NavbarProps) {
  return (
    <nav className={styles.navbar}>
      <div className="max-content-width">
        <Link className={styles.navbarLogo} href="/">
          <Icon name="LogoFullBlack" className={styles.navbarLogoDesktop} />
          <Icon name="LogoMarkBlack" className={styles.navbarLogoMobile} />
        </Link>
        {children}
      </div>
    </nav>
  );
}

export default Navbar;
