import classNames from 'classnames';
import React from 'react';
import styles from './Icon.module.scss';
import { ICONS, getIconComponent } from './icons/index';

export type IconName = keyof typeof ICONS;
export const iconOptions = Object.keys(ICONS) as (keyof typeof ICONS)[];

interface IconProps {
  name: IconName;
  className?: string;
  style?: React.CSSProperties;
}

function Icon({ name, className = '', ...props }: IconProps) {
  const IconComponent = getIconComponent(name);

  return (
    <IconComponent
      data-testid={`icon-${name.toLowerCase()}`}
      className={classNames(styles.tbIcon, name.toLowerCase(), className)}
      {...props}
    />
  );
}

export default Icon;
