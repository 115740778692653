import { z } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  const variableName = issue.path.join('.'); // Handles nested paths
  const value = JSON.stringify(ctx.data);

  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === 'undefined' || issue.received === 'null' || issue.received === 'nan') {
        return { message: `\`${variableName}\` is required.` };
      }
      return {
        message: `\`${variableName}\` expected type \`${issue.expected}\` but received type \`${issue.received}\`.`
      };

    case z.ZodIssueCode.unrecognized_keys:
      return {
        message: `Unrecognized fields in \`${variableName}\`: ${issue.keys.map((key) => `\`${key}\``).join(', ')}.`
      };

    case z.ZodIssueCode.invalid_literal:
      return {
        message: `\`${variableName}\` with value \`${value}\` does not match the required literal value: \`${
          issue.expected as string
        }\`.`
      };

    case z.ZodIssueCode.invalid_enum_value:
      return {
        message: `\`${variableName}\` with value \`${value}\` should be one of the following values: \`${issue.options
          .map((key) => `\`${key}\``)
          .join(', ')}\`.`
      };

    case z.ZodIssueCode.invalid_arguments:
      return { message: `Invalid arguments provided for \`${variableName}\`.` };

    case z.ZodIssueCode.invalid_return_type:
      return { message: `Invalid return type for \`${variableName}\`.` };

    case z.ZodIssueCode.invalid_date:
      return { message: `\`${variableName}\` with value \`${value}\` is not a valid date.` };

    case z.ZodIssueCode.invalid_string:
      if (issue.message) {
        return { message: issue.message };
      }
      if (issue.validation === 'email') {
        return { message: `\`${variableName}\` with value \`${value}\` is not a valid email.` };
      }
      if (issue.validation === 'uuid') {
        return { message: `\`${variableName}\` with value \`${value}\` is not a valid uuid.` };
      }
      if (issue.validation === 'url') {
        return { message: `\`${variableName}\` with value \`${value}\` is not a valid url.` };
      }
      if (issue.validation === 'regex') {
        return { message: `\`${variableName}\` with value \`${value}\` does not match the required regex.` };
      }
      return { message: `Invalid string format for \`${variableName}\`.` };

    case z.ZodIssueCode.too_small: {
      const minimum = issue.inclusive ? 'at least' : 'greater than';
      const suffix = issue.type === 'string' ? 'characters long' : '';
      return {
        message: `\`${variableName}\` with value \`${value}\` is too small. It should be ${minimum} ${issue.minimum} ${suffix}.`
      };
    }
    case z.ZodIssueCode.too_big: {
      const maximum = issue.inclusive ? 'at most' : 'less than';
      const suffix = issue.type === 'string' ? 'characters long' : '';
      return {
        message: `\`${variableName}\` with value \`${value}\` is too large. It should be ${maximum} ${issue.maximum} ${suffix}.`
      };
    }

    case z.ZodIssueCode.custom:
      return {
        message: issue.message ?? `Validation error at  \`${variableName}\`: ${ctx.defaultError}`
      };

    default:
      return { message: `Validation error at \`${variableName}\`: ${ctx.defaultError}` };
  }
};

z.setErrorMap(customErrorMap);
