import { usePrivy } from '@privy-io/react-auth';
import { useCallback, useEffect, useState } from 'react';
import {
  accessTokenAtom,
  authErrorAtom,
  userAuthenticationStateAtom,
  isAuthenticatingAtom,
  userAtom
} from '@trustblock/web/store';
import { useAtom } from 'jotai';
import { AxiosError } from 'axios';
import { isErrorResponse, isValidationErrorResponse } from '@trustblock/helpers/guards';
import { ZodError } from 'zod';
import { HttpStatusCode } from '@trustblock/types/http.types';
import { apiRoutes } from '@trustblock/web/helpers/api';
import { UserAuthenticationState } from '@trustblock/types/user.types';
import useApi from '../global/useApi';

export default function useAuth({ isInAutomaticMode }: { isInAutomaticMode: boolean }) {
  const { request } = useApi();
  const {
    login,
    authenticated,
    logout,
    user: privyUser,
    ready,
    isModalOpen,
    getAccessToken: getAccessTokenLocal
  } = usePrivy();
  // useAtom takes an extra re-render but only once for all useAtoms' calls
  const [userAuthenticationState, setUserAuthenticationState] = useAtom(userAuthenticationStateAtom);
  const [isAuthenticating, setIsAuthenticating] = useAtom(isAuthenticatingAtom);
  const [authError, setAuthError] = useAtom(authErrorAtom);
  const [user, setUser] = useAtom(userAtom);
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [canUserBeFetched, setCanUserBeFetched] = useState(true);
  const [error, setError] = useState<string>();

  const getUser = useCallback(async () => {
    try {
      const response = await request<'getUser'>({
        route: apiRoutes.getUser(),
        withAuthentication: true
      });

      setUser(response.data);
      setAuthError(null);

      return true;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === HttpStatusCode.NotFound && isErrorResponse(err.response.data)) {
          setError(err.response.data.message);
        } else if (err.response && isValidationErrorResponse(err.response.data)) {
          const firstValidationError = Object.values(err.response.data.validationErrors)[0];
          setError(firstValidationError);
        } else setError((err.response?.data as AxiosError)?.message);
      } else if (err instanceof ZodError) {
        setError(err.message);
      }
    }
    return false;
  }, [request, setAuthError, setUser]);

  const getFinalUser = useCallback(async () => {
    setCanUserBeFetched(false);
    setIsAuthenticating(true);
    const success = await getUser();
    if (!success) setUserAuthenticationState(UserAuthenticationState.ConnectedWithoutUser);
    else setUserAuthenticationState(UserAuthenticationState.SignedIn);
    setIsAuthenticating(false);
  }, [setIsAuthenticating, getUser, setUserAuthenticationState]);

  const localLogout = async () => {
    setIsAuthenticating(true);
    await logout();
    setUserAuthenticationState(UserAuthenticationState.Visitor);
    setAccessToken(null);
    setIsAuthenticating(false);
    setAuthError(null);
    setUser(null);
    setCanUserBeFetched(true);
  };

  const getAccessToken = useCallback(async () => {
    const localAccessToken = await getAccessTokenLocal();
    setAccessToken(localAccessToken);
    return localAccessToken;
  }, [getAccessTokenLocal, setAccessToken]);

  const localLogin = async () => {
    setIsAuthenticating(true);
    login();
  };

  useEffect(() => {
    if (!isInAutomaticMode) return;

    if (authenticated && ready && !!user) {
      setUserAuthenticationState(UserAuthenticationState.SignedIn);
    }
    const isPrivyConnected = authenticated && ready && !!privyUser;
    const shouldUserBeFetched = canUserBeFetched && isPrivyConnected;
    const newIsConnectedWithoutUser = isPrivyConnected && !isAuthenticating && !user;
    if (shouldUserBeFetched) {
      void getFinalUser();
    } else if (newIsConnectedWithoutUser) {
      setUserAuthenticationState(UserAuthenticationState.ConnectedWithoutUser);
    } else if (!isPrivyConnected) {
      setIsAuthenticating(false);
    }
    if (error) setAuthError(error);
  }, [
    isInAutomaticMode,
    isAuthenticating,
    authenticated,
    error,
    isModalOpen,
    privyUser,
    ready,
    setAuthError,
    setIsAuthenticating,
    setUser,
    user,
    canUserBeFetched,
    setUserAuthenticationState,
    getFinalUser
  ]);

  return {
    userAuthenticationState,
    getUser: getFinalUser,
    accessToken,
    getAccessToken,
    login: localLogin,
    logout: localLogout,
    authUser: privyUser,
    user,
    error: authError,
    isAuthenticating
  };
}
