export const steps = {
  thousands: 1000,
  millions: 1000000,
  billions: 1000000000
} as const;

type Steps = (typeof steps)[keyof typeof steps];

function insertCommas(number: number): string {
  const numberString = number.toString();
  const parts = numberString.split('.');
  // eslint-disable-next-line security/detect-unsafe-regex
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function truncateString(inputString: string, max: number) {
  if (inputString.length > max) {
    return `${inputString.slice(0, max - 3)}...`;
  }
  return inputString;
}

export function formatNumber(number: number, step: Steps = steps.thousands): string {
  let finalNumber: number | string = number;

  if (finalNumber >= 1000000000) {
    if (step <= steps.billions) {
      finalNumber = (finalNumber / 1000000000).toFixed(1);
      if (finalNumber.endsWith('.0')) {
        finalNumber = finalNumber.slice(0, -2);
      }
      finalNumber = `${finalNumber}bn`;
    } else {
      finalNumber = insertCommas(finalNumber);
    }
  } else if (finalNumber >= 1000000) {
    if (step <= steps.millions) {
      finalNumber = (finalNumber / 1000000).toFixed(1);
      if (finalNumber.endsWith('.0')) {
        finalNumber = finalNumber.slice(0, -2);
      }
      finalNumber = `${finalNumber}m`;
    } else {
      finalNumber = insertCommas(finalNumber);
    }
  } else if (finalNumber >= 1000) {
    if (step <= steps.thousands) {
      finalNumber = (finalNumber / 1000).toFixed(1);
      if (finalNumber.endsWith('.0')) {
        return finalNumber.slice(0, -2);
      }
      finalNumber = `${finalNumber}k`;
    } else {
      finalNumber = insertCommas(finalNumber);
    }
  }

  return finalNumber?.toString();
}

export function joinArrayElements(arrayOfElements: string[]): string {
  return arrayOfElements.join(', ').replace(/, ([^,]*)$/, ' & $1');
}

function isVowel(char: string): boolean {
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
}
export function tryPluralizeWord(word: string, count: number): string {
  if (count === 1) {
    return word;
  }
  if (word.endsWith('s') || word.endsWith('x') || word.endsWith('z') || word.endsWith('ch') || word.endsWith('sh')) {
    return `${word}es`;
  }
  if (word.endsWith('y') && !isVowel(word[word.length - 2])) {
    return word.replace(/y$/, 'ies');
  }
  return `${word}s`;
}

export function convertStringToByte(str: string, byteLength = 32): string {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(str);
  const paddedArray = new Uint8Array(byteLength);
  paddedArray.set(uint8Array);
  const hexString = [...paddedArray].reduce((acc, value) => acc + value.toString(16).padStart(2, '0'), '');
  return `0x${hexString}`;
}

export function daySuffix(day: number): string {
  if (day === 1 || day === 21 || day === 31) {
    return 'st';
  }
  if (day === 2 || day === 22) {
    return 'nd';
  }
  if (day === 3 || day === 23) {
    return 'rd';
  }
  return 'th';
}

export function formatDate(date: string) {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const year = dateObj.getFullYear();

  return `${day}/${formattedMonth}/${year}`;
}

export function cleanObject<T>(obj: Record<string, unknown>): Record<string, T> {
  const cleanObj: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] || obj[key] === 0) {
      // Checks if value is truthy or 0 (as 0 is a real but falsy value)
      cleanObj[key] = obj[key];
    }
  });
  return cleanObj as Record<string, T>;
}

export const formatGithubUrl = (url: string) => {
  let formattedUrl = url.replace(/^(https:\/\/)/, '');
  formattedUrl = formattedUrl.replace(/^(www\.)/, '');
  const parts = formattedUrl.split('/');

  if (parts.length >= 2) {
    return `${parts[1]}/${parts[2]}`;
  }

  return url;
};

export const formatGitHubRawURL = (githubURL: string) => {
  const parts = githubURL.split('/');
  if (parts.length >= 5) {
    const user = parts[3];
    const repo = parts[4];
    const commit = parts[6];
    const filePath = parts.slice(7).join('/');
    return `https://raw.githubusercontent.com/${user}/${repo}/${commit}/${filePath}`;
  }
  throw new Error('Invalid GitHub URL format');
};

export const formatUrl = (url: string) => {
  let formattedUrl = url.trim();
  if (formattedUrl.startsWith('http://')) {
    formattedUrl = formattedUrl.replace('http://', 'https://');
  } else if (!formattedUrl.startsWith('https://')) {
    formattedUrl = `https://${formattedUrl}`;
  }
  return formattedUrl;
};

export const capitalizeWord = (word: string): string => {
  if (!word) return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
