import Icon from '@trustblock/components/standalone/Icon/Icon';
import Link from 'next/link';
import React from 'react';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="max-content-width">
        <div>
          <Icon name="LogoFullBlack" />
          <p className={styles.footerDescription}>
            We make web3 a secured-by-default,
            <br />
            user-friendly, composable ecosystem.
          </p>
          <p>All rights reserved · Trustblock</p>
        </div>
        <div>
          <div>
            <h5>About</h5>
            <Link href="https://trustblock.run">General</Link>
            <Link href="https://trustblock.run/auditors">Auditors</Link>
            <Link href="https://trustblock.run/integrators">Integrators</Link>
            <Link href="https://docs.trustblock.run">Get started</Link>
          </div>
          <div>
            <h5>Let&apos;s connect</h5>
            <Link href="https://twitter.com/TrustblockHQ">Twitter</Link>
            <Link href="https://www.youtube.com/@TrustblockHQ">Youtube</Link>
          </div>
          <div>
            <h5>Backers</h5>
            <Link href="https://fabric.vc">Fabric</Link>
            <Link href="https://frst.vc">Frst</Link>
            <Link href="https://www.lecryptofellowship.com/">LCF</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
