import '@trustblock/web/styles/globals.scss';
import { Analytics } from '@vercel/analytics/react';
import Footer from '@trustblock/components/global/Footer/Footer';
import Navbar from '@trustblock/components/global/Navbar/Navbar';
import { Manrope } from 'next/font/google';
import 'react-responsive-modal/styles.css';
import { AppProps } from 'next/app';
import '@trustblock/validators/setupZod';
import { SpeedInsights } from '@trustblock/components/global/SpeedInsights/SpeedInsights';
import { PostHogProvider } from 'posthog-js/react';
import { PrivyProvider } from '@privy-io/react-auth';
import environment from '@trustblock/constants/environment.constants';
import { initPosthog } from '../helpers/posthog';
import AuthHandler from '../components/_global/AuthHandler/AuthHandler';

if (typeof window !== 'undefined') {
  initPosthog();
}
const manrope = Manrope({ subsets: ['latin'], display: 'swap' });

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <SpeedInsights />
      <Analytics />
      <style jsx global>
        {`
          html,
          #privy-modal-content,
          #privy-modal-content * {
            font-family: ${manrope.style.fontFamily};
          }

          :root {
            --manrope-font: ${manrope.style.fontFamily};
          }
        `}
      </style>
      <PostHogProvider>
        <PrivyProvider
          appId={environment.PRIVY_APP_ID}
          config={{
            appearance: {
              logo: ''
            }
          }}
        >
          <Navbar>
            <AuthHandler />
          </Navbar>
          <Component {...pageProps} />
          <Footer />
        </PrivyProvider>
      </PostHogProvider>
    </>
  );
}

export default App;
