import type { UserValidator } from '@trustblock/validators/user.validators';
import type { LinksPublic, RequestResponseBase } from './global.types';
import type { AuditorPrivate } from './auditor.types';
import type { IntegratorPrivate } from './integrator.types';

export enum UserType {
  Auditor = 'auditor',
  Integrator = 'integrator'
}
export const userTypes = Object.values(UserType);

export enum UserAuthenticationState {
  Visitor = 'visitor',
  ConnectedWithoutUser = 'connectedWithoutUser',
  SignedIn = 'signedIn'
}

export type UserPrivate = Omit<UserValidator, 'createdAt'> & {
  apiCreditsCount: number;
  links: LinksPublic;
  createdAt: number;
};

export type UserPublic = Omit<UserValidator, 'apiKey' | 'createdAt'> & {
  links: LinksPublic;
  createdAt: number;
};

export type GetUserResponseData = AuditorPrivate | IntegratorPrivate;
export type GetUserResponse = RequestResponseBase & GetUserResponseData;

export type UpdateUserProfilePictureResponse = RequestResponseBase & {
  profilePictureUrl: string;
};

export enum UserStatus {
  Registered = 'registered',
  Onboarded = 'onboarded',
  Verified = 'verified'
}
export const userStatuses = Object.values(UserStatus);
