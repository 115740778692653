import React from 'react';
import styles from './Loading.module.scss';
import Spinner from './Oval';

interface LoadingProps {
  mode?: 'global' | 'button';
}

function Loading({ mode = 'global' }: LoadingProps) {
  const modeClassname = styles[`tbLoading${mode.charAt(0).toUpperCase() + mode.slice(1)}`];

  return <Spinner className={modeClassname} />;
}

export default Loading;
