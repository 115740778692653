import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import Icon, { IconName } from '../Icon/Icon';
import Loading from '../Loading/Loading';
import styles from './Button.module.scss';

interface ButtonProps {
  icon?: IconName;
  mode?: 'primary' | 'secondary' | 'outline';
  fill?: boolean;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'link';
  disabled?: boolean;
  onClick?: () => void | Promise<void>;
  loading?: boolean;
  iconPosition?: 'left' | 'right';
  href?: string;
  target?: string;
  onlyIcon?: boolean;
  active?: boolean;
  noColorSwitch?: boolean;
  className?: string;
}

function Button({
  mode = 'secondary',
  fill = false,
  children,
  type = 'button',
  disabled = false,
  onClick,
  icon,
  loading = false,
  iconPosition = 'left',
  href,
  target,
  onlyIcon = false,
  active = false,
  noColorSwitch,
  className,
  ...props
}: ButtonProps) {
  const modeClassName = styles[`tbButton${mode.charAt(0).toUpperCase() + mode.slice(1)}`];

  const finalClassName = classNames(modeClassName, className, styles.tbButton, {
    [styles.tbButtonFill]: fill,
    [styles.tbButtonDisabled]: loading || disabled,
    [styles.tbButtonIconRight]: iconPosition === 'right',
    [styles.tbButtonOnlyIcon]: onlyIcon,
    [styles.tbButtonActive]: active,
    [styles.tbButtonNoIcon]: !(icon ?? loading),
    [styles.tbButtonNoColorSwitch]: noColorSwitch
  });

  const defaultContent = (
    <>
      {loading && <Loading mode="button" />}
      {icon && !loading && <Icon name={icon} />}
    </>
  );
  if (type === 'submit') {
    return (
      <button className={finalClassName} disabled={disabled || loading} type="submit" onClick={onClick} {...props}>
        <div>
          {iconPosition === 'left' && defaultContent}
          {children}
          {iconPosition === 'right' && defaultContent}
        </div>
      </button>
    );
  }

  if (type === 'button') {
    return (
      <button className={finalClassName} disabled={disabled || loading} type="button" onClick={onClick} {...props}>
        <div>
          {iconPosition === 'left' && defaultContent}
          {children}
          {iconPosition === 'right' && defaultContent}
        </div>
      </button>
    );
  }

  return (
    <Link href={href ?? ''} target={target} className={finalClassName} onClick={onClick} {...props}>
      <div>
        {defaultContent}
        {children}
      </div>
    </Link>
  );
}

export default Button;
